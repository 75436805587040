import React, { Suspense } from 'react';

import ReactDOM from 'react-dom';

import './index.css';

import { WhiteLabelWrapper } from './main/hooks/useWhitelabel';
import * as serviceWorker from './serviceWorker';

const App = React.lazy(() => import('./App'));

const AppWrapper = () => (
  <WhiteLabelWrapper>
    <App />
  </WhiteLabelWrapper>
);

const Main = () => (
  <Suspense fallback={null}>
    <AppWrapper />
  </Suspense>
);

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
