import React, { Suspense, createContext, useState, useEffect } from 'react';

import styled from 'styled-components';

import { fetchStoredWhitelabelValues, fetchWhitelabelValues } from '../whitelabel';

type WhitelabelValues = {
  host?: string,
  assets?: { [key: string]: string },
  labels?: { [key: string]: string },
  colors?: { [key: string]: string },
  manifest?: { [key: string]: string },
  translations?: { [key: string]: string },
};

export type Whitelabel = {
  values: WhitelabelValues,
  setValues: any,
};

const WhitelabelContext = createContext({
  values: {},
  setValues: () => {},
});

// We have to set another Promise that will be resolved when the last updated values are fetch
// Eg: When the whitelabel values are stored in local storage, we fetch from it first and resolve the Suspense
// Then we have to re-render with the updated values if different
let fetchResolve = null;
const fetchPromise = new Promise(resolve => fetchResolve = resolve);
const whitelabelValues = fetchWhitelabelValues(fetchResolve);

type Props = {
  children: React.Node,
};

const ErrorContainer = styled.div`
  color: #E80539;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WhiteLabelWrapper = ({ children }: Props) => {
  const [values, setValues] = useState(whitelabelValues.read());

  useEffect(async () => {
    await fetchPromise;
    const newValues = fetchStoredWhitelabelValues();
    if (JSON.stringify(values) !== JSON.stringify(newValues)) {
      setValues(newValues);
    }
  }, []);

  if (!values) {
    // Can't require i18n on the top of this file or translation won't be overwritten
    const i18n = require('../i18n').default;
    return (
      <ErrorContainer>
        {i18n.t('errors:whitelabelFetchError')}
      </ErrorContainer>
    );
  }

  return (
    <WhitelabelContext.Provider value={{ values, setValues }}>
      <Suspense>
        {children}
      </Suspense>
    </WhitelabelContext.Provider>
  );
};

const useWhitelabel: () => Whitelabel = () => React.useContext(WhitelabelContext);

export default useWhitelabel;
